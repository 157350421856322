import React, { useEffect, useState } from "react";
import "./MarkAttendence.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../../common/Utils/constant";
import UserOne from "../../../images/user/user-01.png";
import { markAttendence } from "../ManageMarkAttendence/Dependencies/action";

const MarkAttendence = () => {
  const [clock, setClock] = useState("");
  const [markIn, setMarkIn] = useState("");

  const [markOut, setMarkOut] = useState("");
  const [salaryData, setSalaryData] = useState({});

  useEffect(() => {
    clockRunner();
    getAttendence();
    // getSalary();
  }, []);

  // const getSalary = () => {
  //   axios
  //     .get(`${API_URL}/user/salary`, {
  //       headers: {
  //         "content-type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data?.data) {
  //         setSalaryData(res.data?.data);
  //         console.log(res.data?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       if (err?.message) {
  //         console.log(err.response.data.message);
  //         // alert(err.response.data.message)
  //         window.location.reload();
  //       }
  //     });
  // };

  const clockRunner = () => {
    let time = moment().format("dddd h:mm:ss");
    setClock(time);
    setTimeout(clockRunner, 1000);
  };
  const getAttendence = () => {
    axios
      .get(`${API_URL}/user/attendence/mark`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
      })
      .then((res) => {
        if (res.data?.data) {
          setMarkIn(res.data?.data.check_in);
          setMarkOut(res.data?.data.check_out);
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.log(err.response.data.message);
          // alert(err.response.data.message)
          window.location.reload();
        }
      });
  };
  const MarkAttendenceInOut = async (checkIn: boolean) => {
    let payload = {};
    if (checkIn) {
      payload["markType"] = "IN";
    } else {
      payload["markType"] = "OUT";
    }

    // payload["login_date"] = new Date().toJSON().slice(0, 10);
    await markAttendence(payload);
    getAttendence();
  };
  const { authUserData } = useSelector((state: any) => state.authReducer);
  return (
    <>
      <div className="bg-gray-100 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-2xl font-semibold">Employee Attendance</h1>
          <div className="text-center my-4">
            <img
              className="h-32 w-32 rounded-full border-4 border-white dark:border-gray-800 mx-auto my-4"
              src={UserOne}
              alt=""
            />
            <div className="py-2">
              <h3 className="font-bold text-2xl text-gray-800 dark:text-white mb-1">
                {authUserData.user_name}
              </h3>
              <div className="inline-flex text-gray-700 dark:text-gray-300 items-center">
                <svg
                  className="h-5 w-5 text-gray-400 dark:text-gray-600 mr-1"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    className=""
                    d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 1 0-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                  />
                </svg>
                New Delhi
              </div>
            </div>
          </div>
          <div className="flex justify-center my-4">
            <h2 className="text-xl font-bold">{clock}</h2>
          </div>
          <div className="flex justify-center mb-4">
            {!markIn && (
              <button
                id="mark-in"
                className="px-4 py-2 bg-primary hover:bg-secondary text-white rounded-md"
                onClick={() => MarkAttendenceInOut(true)}
              >
                Mark In
              </button>
            )}
            {!markOut && markIn && (
              <button
                id="mark-out"
                className="px-4 py-2 bg-danger hover:bg-secondary text-white rounded-md"
                onClick={() => MarkAttendenceInOut(false)}
              >
                Mark Out
              </button>
            )}
          </div>

          <div className="flex justify-center mt-4 font-bold">
            <h2>Mark In: {markIn}</h2>
          </div>
          <div className="flex justify-center mt-4 font-bold">
            <h2>Mark Out: {markOut}</h2>
          </div>
          {/* {salaryData.salary_amount && (
            <>
              <div className="flex justify-center mt-4 font-bold">
                <h2>
                  Salary:
                  {salaryData.salary_amount}
                </h2>
              </div>
              <div className="flex justify-center mt-4 font-bold">
                <h2>
                  Remark:
                  {salaryData.remarks}
                </h2>
              </div>
            </>
          )} */}
        </div>
      </div>
    </>
  );
};

export default MarkAttendence;
