import axios from "axios";
import { API_URL } from "../../../../common/Utils/constant";
import moment from "moment";

export const getAttendenceRecord = (payload) => {
    return axios.post(`${API_URL}/user/attendence/all`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        }
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e);
        return false;
    })
}

export const getAttendenceLogsRecord = (payload) => {
    return axios.post(`${API_URL}/attendence/salary/all`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        }
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e);
        return false;
    })
}

export const getSalary = (payload) => {
    return axios
        .post(`${API_URL}/user/salary`, payload, {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
            },
        })
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            if (err?.message) {
                console.log(err);
            }
        });
}


export const markAttendence = (payload) => {
    return axios.post(`${API_URL}/user/attendence/mark`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        // alert(res.data.message);
        return true
    }).catch((err) => {
        if (err?.message) {
            // console.log(err.response.data.message)
            alert(err.response.data.message)
            window.location.reload();
        }
        return false
    })
}


export const adminMarkAttendence = (payload) => {
    return axios.post(`${API_URL}/admin/attendence/mark`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        // alert(res.data.message);
        return true
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
            window.location.reload();
        }
        return false
    })
}

export const deleteAttendence = (id) => {
    return axios.post(`${API_URL}/admin/attendence/delete/${id}`, {}, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        // alert(res.data.message);
        return true
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
            window.location.reload();
        }
        return false
    })
}

export const syncAttendence = () => {
    return axios.get(`${API_URL}/attendence/salary`, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        alert('Sync is Completed');
        return true
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
            window.location.reload();
        }
        return false
    })
}