import React, { useEffect, useRef, useState } from "react";
import "./ManageMarkAttendence.scss";
import { Field } from "formik";
import {
  adminMarkAttendence,
  deleteAttendence,
  getAttendenceRecord,
  markAttendence,
} from "./Dependencies/action.tsx";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CSVLink } from "react-csv";
import TransitionsModal from "../../../components/attendenceModal/attendenceModal.tsx";
const ManageMarkAttendence = () => {
  const [attendenceMessage, setAttendenceMessage] = useState({
    type: "",
    reason: "",
  });
  const [openOrder, setOpenOrder] = useState(false);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [attendence, setAttendence] = useState([]);
  const [selectMonth, setSelectMonth] = useState(moment().format("MMM"));

  const { users } = useSelector((state: any) => state.userReducer);
  const [checked, setChecked] = useState();
  const [attendence_id, setAttendenceId] = useState();
  const [day, setDay] = useState();
  const [user_id, setUserId] = useState();

  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    const selectDays = moment(`${moment().format("YYYY")}-${selectMonth}-01`)
      .endOf("month")
      .format("DD");
    const payload = {
      firstdate: moment(`${moment().format("YYYY")}-${selectMonth}-01`)
        .startOf("month")
        .format("YYYY-MM-DD"),
      lastdate: moment(`${moment().format("YYYY")}-${selectMonth}-01`)
        .endOf("month")
        .format("YYYY-MM-DD"),
    };
    getAttendenceRecord(payload).then((res) => {
      if (res?.data) {
        const days = [];
        users.forEach((user, userIndex) => {
          days.push({
            user,
            attendence: [],
            count: 0,
            present_count: 0,
            late_count: 0,
            paid_leave: 0,
            half_day: 0,
          });
          for (let index = 0; index < parseInt(selectDays); index++) {
            days[userIndex].attendence.push({
              present: false,
              late: false,
              paid_leave: false,
              half_day: false,
              clock_in: false,
              attendence_id: 0,
            });
          }
        });
        users.forEach(({ user_id, user_name }, index) => {
          res.data.forEach((d) => {
            console.log(d);
            if (user_id == d.user_id) {
              console.log("attendence", d);
              const date = parseInt(moment(d.login_date).format("DD"));

              switch (d.attendence_message) {
                case "PL":
                  days[index].attendence[date - 1].paid_leave = true;

                  days[index].paid_leave++;

                  break;
                case "L":
                  days[index].attendence[date - 1].late = true;

                  days[index].late_count++;

                  break;
                case "P":
                  days[index].attendence[date - 1].present = true;

                  days[index].present_count++;
                  break;
                case "HD":
                  days[index].attendence[date - 1].half_day = true;

                  days[index].half_day++;
                  break;

                default:
                  break;
              }
              days[index].attendence[date - 1].clock_in = true;
              days[index].count++;
              days[index].attendence[date - 1].attendence_id = d.attendence_id;
            }
          });
        });

        console.log(days);
        setAttendence(days);
      }
    });
  }, [selectMonth]);

  const csvInstance = useRef();
  const addAttendenceMark = async (checked, attendence_id, day, user_id) => {
    // console.log(checked, attendence_id, day)
    setChecked(checked);
    setAttendenceId(attendence_id);
    setDay(day);
    setUserId(user_id);
    setOpenOrder(true);
  };

  useEffect(() => {
    const dataResult = attendence.map(({ user, attendence, count }) => {
      const obj = {};
      obj["name"] = user.user_name;
      attendence.forEach((d, index) => {
        obj[`key_${index + 1}`] = d.present
          ? "P"
          : d.late
            ? "L"
            : d.paid_leave
              ? "PL"
              : d.half_day
                ? "HD"
                : "WO";
      });
      obj["count"] = count;
      return obj;
    });
    // dispatch(getLoader(false));
    console.log(dataResult);
    setExcelData(dataResult);
  }, [attendence]);

  const dataDownloadExcel = async () => {
    csvInstance.current.link.click();
  };

  const excelHeader =
    attendence.length > 0
      ? [
        { label: "Name", key: "name" },
        ...attendence[0].attendence.map((d, index) => {
          return { label: index + 1, key: `key_${index + 1}` };
        }),
        { label: "Count", key: "count" },
      ]
      : [];
  // { label: "Lead Id", key: "lead_id" },
  // { label: "Name", key: "name" },
  // { label: "Mobile Number", key: "mobile_number" },
  // { label: "Products", key: "products" },
  // { label: "Loan Amount", key: "loan_amount" },
  // { label: "Sanctioned Amount", key: "sanctioned_amount" },
  // { label: "Disbursed Amount", key: "disbursed_amount" },
  // { label: "Lead Status", key: "lead_status" },
  // { label: "Lead Remark", key: "lead_remark" },
  // { label: "Created On", key: "created_on" }
  // ]
  return (
    attendence.length > 0 && (
      <>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 overflow-x-auto">
          <div className="manage-mark-attendence">
            <h1 className="text-xl mb-5 text-center underline">
              Manage Attendance
            </h1>
            <div className="grid grid-cols-2 mb-5">
              <select
                name="lead_taken_by"
                value={selectMonth}
                onChange={(e) => setSelectMonth(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                placeholder="Enter your Full Name"
              >
                <option value="" defaultChecked>
                  -----
                </option>
                {months.map((user) => {
                  return <option value={user}>{user}</option>;
                })}
              </select>
            </div>
            <table>
              <thead>
                <tr>
                  <th className="name-col">Employee Name</th>
                  {attendence[0].attendence.map((d, index) => (
                    <th>{index + 1}</th>
                  ))}
                  <th className="missed-col">Days Missed-col</th>
                </tr>
              </thead>
              <tbody>
                {attendence.map(({ user, attendence, count }) => (
                  <>
                    <tr className="student">
                      <td className="name-col">{user.user_name}</td>
                      {attendence.map((d, index) => (
                        <td
                          className={`attend-col ${d.present ? "bg-[#92d050]" : ""
                            } ${d.late ? "bg-danger" : ""} ${d.paid_leave ? "bg-[#c00000]" : ""
                            } ${d.half_day ? "bg-[#fbe5d6]" : ""}`}
                        >
                          <input
                            checked={d.clock_in}
                            onChange={(e) => {
                              addAttendenceMark(
                                e.target.checked,
                                d.attendence_id,
                                index + 1,
                                user.user_id
                              );
                            }}
                            type="checkbox"
                          />
                        </td>
                      ))}
                      <td className="missed-col">{count}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <TableContainer component={Paper} className="mt-5">
          <Table className="w-full table-auto">
            <TableHead>
              <TableRow className="bg-gray-2 text-left dark:bg-meta-4">
                <TableCell className="min-w-[120px] py-4 px-4 font-bold text-black dark:text-white">
                  Name
                </TableCell>
                <TableCell className="min-w-[180px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                  Totals days of month
                </TableCell>
                <TableCell className="py-4 px-4 font-bold text-black dark:text-white xl:pl-11 bg-[#92d050]">
                  P
                </TableCell>
                <TableCell className="py-4 px-4 font-bold text-black dark:text-white bg-danger">
                  L
                </TableCell>
                <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                  WO
                </TableCell>
                <TableCell className="py-4 px-4 font-bold text-black dark:text-white bg-[#c00000]">
                  PL
                </TableCell>
                <TableCell className="py-4 px-4 font-bold text-black dark:text-white bg-[#fbe5d6]">
                  HD
                </TableCell>
                <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                  Pending Leaves
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, id) => (
                <TableRow key={id}>
                  <TableCell className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <h5 className="font-medium text-black dark:text-white">
                      {user.user_name}
                    </h5>
                  </TableCell>
                  <TableCell className="border-b border-[#eee] cursor-pointer py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <h5 className="font-medium text-black hover:text-[#e1ad01] dark:text-white">
                      <p className="text-black dark:text-white">
                        {" "}
                        {attendence[0].attendence.length}
                      </p>
                    </h5>
                  </TableCell>
                  <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {attendence[id].present_count}
                    </p>
                  </TableCell>

                  <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {attendence[id].late_count}
                    </p>
                  </TableCell>
                  <TableCell
                    className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                  >
                    <p className="text-black dark:text-white">
                      {attendence[0].attendence.length -
                        attendence[id].present_count -
                        attendence[id].late_count -
                        attendence[id].paid_leave -
                        attendence[id].half_day}
                    </p>
                  </TableCell>
                  <TableCell
                    className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                  >
                    <p className="text-black dark:text-white">
                      {attendence[id].paid_leave}
                    </p>
                  </TableCell>
                  <TableCell
                    className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                  >
                    <p className="text-black dark:text-white">
                      {attendence[id].half_day}
                    </p>
                  </TableCell>
                  <TableCell
                    className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                  >
                    <p className="text-black dark:text-white">0</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TransitionsModal
          checked={checked}
          selectMonth={selectMonth}
          day={day}
          user_id={user_id}
          attendence_id={attendence_id}
          setOpenOrder={setOpenOrder}
          openOrder={openOrder}
        />
        <CSVLink
          headers={excelHeader}
          data={excelData}
          filename="All_Leads.csv"
          ref={csvInstance}
        ></CSVLink>
        <button
          type="button"
          className="text-white mt-5 float-right bg-primary hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none"
          onClick={dataDownloadExcel}
        >
          Export All Leads
        </button>
      </>
    )
  );
};

export default ManageMarkAttendence;
