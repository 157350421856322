import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { getAttendenceLogsRecord, getSalary, syncAttendence } from '../ManageMarkAttendence/Dependencies/action';
import { useSelector } from 'react-redux';
import TransitionsModal from '../../../components/attendenceModal/attendenceModal';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const ShowMonthlyAttendence = () => {

    const [selectMonth, setSelectMonth] = useState(moment().format("MMM"));

    const [attendence, setAttendence] = useState([]);
    const { users } = useSelector((state: any) => state.userReducer);
    const { authUserData } = useSelector((state: any) => state.authReducer);
    const [salary, setSalary] = useState([]);

    const runProgram = () => {
        getAttendence();
        const payload = {
            firstdate: moment(`${moment().format("YYYY")}-${selectMonth}-01`)
                .startOf("month")
                .format("YYYY-MM-DD"),
            lastdate: moment(`${moment().format("YYYY")}-${selectMonth}-01`)
                .endOf("month")
                .format("YYYY-MM-DD"),
        };
        getSalary(payload).then((res) => {
            setSalary(res.data);
        });
    }
    useEffect(() => {
        runProgram();
    }, [selectMonth]);

    useEffect(() => {
        const getData = async () => {
            const data = await syncAttendence();
            runProgram();
        }
        getData();
    }, [])

    const getAttendence = () => {
        const selectDays = moment(`${moment().format("YYYY")}-${selectMonth}-01`)
            .endOf("month")
            .format("DD");
        const payload = {
            firstdate: moment(`${moment().format("YYYY")}-${selectMonth}-01`)
                .startOf("month")
                .format("YYYY-MM-DD"),
            lastdate: moment(`${moment().format("YYYY")}-${selectMonth}-01`)
                .endOf("month")
                .format("YYYY-MM-DD"),
        };
        getAttendenceLogsRecord(payload).then((res) => {
            console.log(res.data)
            if (res?.data) {
                const days = [];
                users.forEach((user, userIndex) => {
                    days.push({
                        user,
                        attendence: [],
                        count: 0,
                        present_count: 0,
                        late_count: 0,
                        paid_leave: 0,
                        half_day: 0,
                    });
                    for (let index = 0; index < parseInt(selectDays); index++) {
                        days[userIndex].attendence.push({
                            present: false,
                            late: false,
                            paid_leave: false,
                            half_day: false,
                            // clock_in: false,
                            attendence_message: "",
                            attendence_logs_id: 0,
                        });
                    }
                });
                users.forEach(({ user_id, user_name }, index) => {
                    res.data.forEach((d, i) => {
                        if (user_id == d.user_id) {
                            const date = parseInt(moment(d.attendence_logs_date).format("DD"));
                            days[index].attendence[date - 1].attendence_message = d.attendence_message
                            //   "WO", "EHT", "HL", "NJ"]
                            switch (d.attendence_message) {
                                case "PL":
                                    days[index].attendence[date - 1].paid_leave = true;

                                    days[index].paid_leave++;

                                    break;
                                case "L":
                                    days[index].attendence[date - 1].late = true;

                                    days[index].late_count++;

                                    break;
                                case "P":
                                    days[index].attendence[date - 1].present = true;

                                    days[index].present_count++;
                                    break;
                                case "HD":
                                    days[index].attendence[date - 1].half_day = true;

                                    days[index].half_day++;
                                    break;

                                default:
                                    break;
                            }
                            // days[index].attendence[date - 1].clock_in = true;
                            days[index].count++;
                            days[index].attendence[date - 1].attendence_logs_id = d.attendence_logs_id;
                        }
                    });
                });

                setAttendence(days);
            }
        })
    }

    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    return (
        attendence.length > 0 && (
            <>
                <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 overflow-x-auto">
                    <div className="manage-mark-attendence">
                        <h1 className="text-xl mb-5 text-center underline">
                            Manage Attendance
                        </h1>
                        <div className="grid grid-cols-2 mb-5">
                            <select
                                name="lead_taken_by"
                                value={selectMonth}
                                onChange={(e) => setSelectMonth(e.target.value)}
                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                placeholder="Enter your Full Name"
                            >
                                <option value="" defaultChecked>
                                    -----
                                </option>
                                {months.map((user) => {
                                    return <option value={user}>{user}</option>;
                                })}
                            </select>
                            {/* <button className="flex w-[30%] justify-self-end justify-center rounded bg-primary p-3 font-medium text-gray" onClick={syncAttendence}>
                                Sync
                            </button> */}

                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th className="name-col">Employee Name</th>
                                    {attendence[0].attendence.map((d, index) => (
                                        <th>{index + 1}</th>
                                    ))}
                                    <th className="missed-col">Days Missed-col</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attendence.map(({ user, attendence, count }) => (
                                    <>
                                        <tr className="student">
                                            <td className="name-col">{user.user_name}</td>
                                            {attendence.map((d, index) => (
                                                <td
                                                    className={`attend-col`}
                                                >
                                                    <TableCell className={`py-4 px-4 font-bold text-black dark:text-white xl:pl-11 ${d.present ? "bg-[#92d050]" : ""
                                                        } ${d.late ? "bg-danger" : ""} ${d.paid_leave ? "bg-[#c00000]" : ""
                                                        } ${d.half_day ? "bg-[#fbe5d6]" : ""}`}>
                                                        {d.attendence_message}
                                                    </TableCell>
                                                </td>
                                            ))}
                                            <td className="missed-col">{count}</td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <TableContainer component={Paper} className="mt-5">
                    <Table className="w-full table-auto">
                        <TableHead>
                            <TableRow className="bg-gray-2 text-left dark:bg-meta-4">
                                <TableCell className="min-w-[120px] py-4 px-4 font-bold text-black dark:text-white">
                                    Name
                                </TableCell>
                                <TableCell className="min-w-[180px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                                    Totals days of month
                                </TableCell>
                                <TableCell className="py-4 px-4 font-bold text-black dark:text-white xl:pl-11 bg-[#92d050]">
                                    P
                                </TableCell>
                                <TableCell className="py-4 px-4 font-bold text-black dark:text-white bg-danger">
                                    L
                                </TableCell>
                                <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                    WO
                                </TableCell>
                                <TableCell className="py-4 px-4 font-bold text-black dark:text-white bg-[#c00000]">
                                    PL
                                </TableCell>
                                <TableCell className="py-4 px-4 font-bold text-black dark:text-white bg-[#fbe5d6]">
                                    HD
                                </TableCell>
                                <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                    Pending Leaves
                                </TableCell>
                                <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                    CTC
                                </TableCell>
                                <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                    Remarks
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {['ADMIN', 'MANAGER'].includes(authUserData.user_type) ? users.map((user, id) => (
                                <TableRow key={id} >
                                    <TableCell className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                        <h5 className="font-medium text-black dark:text-white">
                                            {user.user_name}
                                        </h5>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] cursor-pointer py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                        <h5 className="font-medium text-black hover:text-[#e1ad01] dark:text-white">
                                            <p className="text-black dark:text-white">
                                                {" "}
                                                {attendence[0].attendence.length}
                                            </p>
                                        </h5>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">
                                            {attendence[id].present_count}
                                        </p>
                                    </TableCell>

                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">
                                            {attendence[id].late_count}
                                        </p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">
                                            {attendence[0].attendence.length -
                                                attendence[id].present_count -
                                                attendence[id].late_count -
                                                attendence[id].paid_leave -
                                                attendence[id].half_day}
                                        </p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">
                                            {attendence[id].paid_leave}
                                        </p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">
                                            {attendence[id].half_day}
                                        </p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">0</p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">₹{salary.length > 0 ? salary.filter(({ user_id }) => user.user_id == user_id)[0]?.salary_amount : 0}</p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">{salary.length > 0 ? salary.filter(({ user_id }) => user.user_id == user_id)[0]?.remarks : 0}</p>
                                    </TableCell>
                                </TableRow>
                            )) : users.map((user, id) => (
                                authUserData.user_id === user.user_id && <TableRow key={id} >
                                    <TableCell className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                        <h5 className="font-medium text-black dark:text-white">
                                            {user.user_name}
                                        </h5>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] cursor-pointer py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                        <h5 className="font-medium text-black hover:text-[#e1ad01] dark:text-white">
                                            <p className="text-black dark:text-white">
                                                {" "}
                                                {attendence[0].attendence.length}
                                            </p>
                                        </h5>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">
                                            {attendence[id].present_count}
                                        </p>
                                    </TableCell>

                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">
                                            {attendence[id].late_count}
                                        </p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">
                                            {attendence[0].attendence.length -
                                                attendence[id].present_count -
                                                attendence[id].late_count -
                                                attendence[id].paid_leave -
                                                attendence[id].half_day}
                                        </p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">
                                            {attendence[id].paid_leave}
                                        </p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">
                                            {attendence[id].half_day}
                                        </p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">0</p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">₹{salary.length > 0 ? salary.filter(({ user_id }) => user.user_id == user_id)[0]?.salary_amount : 0}</p>
                                    </TableCell>
                                    <TableCell
                                        className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}
                                    >
                                        <p className="text-black dark:text-white">{salary.length > 0 ? salary.filter(({ user_id }) => user.user_id == user_id)[0]?.remarks : 0}</p>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer >
                {/* <TransitionsModal
                    checked={checked}
                    selectMonth={selectMonth}
                    day={day}
                    user_id={user_id}
                    attendence_id={attendence_id}
                    setOpenOrder={setOpenOrder}
                    openOrder={openOrder}
                /> */}
            </>
        )
    )
}

export default ShowMonthlyAttendence
